import { navigate } from 'gatsby-link';
import React, { useState } from 'react';
import styled from 'styled-components';

import Button from './Button';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const FormStyles = styled.form`
  user-select: none;
  place-self: center;
  .input-wrapper {
    display: flex;
    padding: 0 0 2rem 0;
  }
  label {
    font-weight: 200;
  }
  small {
    font-size: 1.8rem;
  }
  label.text-field {
    padding-right: 1rem;
  }
  input {
    flex: 1;
    font-size: 1.8rem;
    border-bottom: 2px dotted var(--black);
    font-weight: 200;
    color: var(--black);
    :focus {
      outline: 0 !important;
    }
  }
  label.checkbox {
    padding: 0 var(--margin) 2rem 0;
    display: block;
    position: relative;
    padding-left: 35px;
    /* margin-bottom: 12px; */
    cursor: pointer;
    /* font-size: 20px; */
    /* user-select: none; */
    & span {
      position: absolute;
      border-bottom: 2px dotted var(--black);
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
    }
    & span:after {
      content: '';
      position: absolute;
      display: none;

      left: 10px;
      top: 6px;
      width: 6px;
      height: 13px;
      border: solid var(--yellow);
      border-width: 0 2px 2px 0;

      transform: rotate(45deg);
    }
    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    &:hover input ~ span {
      background-color: var(-white-grey);
    }
    & input:checked ~ span {
      transform: scale(1.1);
      transition: transform 0.3s cubic-bezier(1, 0.07, 0, 2.21);
      /* background-color: var(--yellow); */
    }
    & input:checked ~ span:after {
      display: block;
    }
  }
`;

export default function Form() {
  const [state, setState] = useState({
    isValidated: false,
    'Tilaan ELY-kehittämispalvelut -oppaan': '-',
    'Haluan, että minuun otetaan yhteyttä': '-',
    // Viesti: "-",
    // Nimi: "-",
    Yritys: '-',
    // Puhelin: "-",
    Sähköposti: '-',
  });
  const [toggle, setToggle] = useState({
    checkbox1: false,
    checkbox2: false,
  });

  const handleChange = ({ target }) => {
    setState({ ...state, [target.name]: target.value });
  };

  const handleToggle = ({ target }) => {
    console.log(target);
    setToggle((state) => ({ ...state, [target.id]: !state[target.id] }));
    setState({ ...state, [target.name]: target.value });
  };

  const labelSwitch = (data) => {
    switch (data) {
      case 'checkbox1':
        return 'Tilaan ELY-kehittämispalvelut -oppaan';
      case 'checkbox2':
        return 'Haluan, että minuun otetaan yhteyttä';
      default:
        return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };
  return (
    <FormStyles
      name="Yhteydenottolomake"
      method="post"
      action="/kiitos/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <div hidden>
        <label>
          Don’t fill this out:
          <input name="bot-field" onChange={handleChange} />
        </label>
      </div>
      <fieldset>
        <div className="input-wrapper">
          <label className="text-field" htmlFor="yritys">
            Yritys
          </label>
          <input
            onChange={handleChange}
            name="Yritys"
            type="text"
            id="yritys"
            // required
            minLength="2"
            maxLength="40"
          />
        </div>
        <div className="input-wrapper">
          <label className="text-field" htmlFor="sähköposti">
            Sähköposti
          </label>
          <input
            onChange={handleChange}
            name="Sähköposti"
            type="email"
            id="sähköposti"
          />
        </div>
      </fieldset>
      <fieldset>
        {Object.keys(toggle).map((key) => (
          <label className="checkbox">
            {labelSwitch(key)}
            <input
              type="checkbox"
              onClick={handleToggle}
              key={key}
              name={labelSwitch(key)}
              id={key}
              value={!toggle[key] ? 'KYLLÄ' : '-'}
              checked={toggle[key]}
            />
            <span />
          </label>
        ))}
        <small>
          Lomakkeen palauttamalla hyväksyn henkilötietojeni käytön
          tietosuojaselosteen mukaisiin markkinointitarkoituksiin.
        </small>
      </fieldset>
      <Button>Lähetä</Button>
    </FormStyles>
  );
}
