import React from 'react';
import { Helmet } from 'react-helmet';

import tietosuojaseloste from '../assets/files/tietosuojaseloste.pdf';
import Hero from '../components/Hero';
import Form from '../components/PageComponents/Form';
import { Subgrid, TwoColumnGrid } from '../styles/Grids';
import PageStyles from '../styles/PageStyles';

export default function OtaYhteyttaPage() {
  return (
    <PageStyles>
      <Helmet
        htmlAttributes={{
          lang: 'fi',
        }}
      >
        <title>Tilaa maksuton kehittämispalvelut-opas - Digis</title>
        <meta
          name="description"
          content="Käynnistä digimarkkinointi järkevästi ja hallitusti ELY-yritystuen avulla. Ota yhteyttä ELY-vastuuasiantuntijaamme. Me kerromme lisää."
        />
      </Helmet>
      <Hero>
        PYYDÄ MEIDÄT
        <br /> KÄYMÄÄN. TILAA
        <br /> <span>ELY-OPAS.</span>
      </Hero>
      <TwoColumnGrid>
        <Subgrid>
          <span className="bar" />
          <div className="contact">
            <h2>
              <span className="cursive">Jätä </span>
              YHTEYDENOTTO&shy;PYYNTÖ tai soita. Toimimme pää&shy;asiassa Turun
              talous&shy;alueella.
              <span className="bold"> ELY</span>-KEHITTÄMIS&shy;PALVELUISSA
              toimi&shy;alueemme on
              <span className="cursive"> koko Suomi.</span>
            </h2>
          </div>
        </Subgrid>
        <Form />
      </TwoColumnGrid>
      <section>
        <h3>
          <span className="cursive">Yrityksen tiedot:</span>
        </h3>
        <p className="info">Digitaalinen Markkinointi Digis</p>
        <p className="info">
          ELY-kehittämispalveluiden palveluntuottaja vv. 2021-2022,
          erityisalueena markkinointi, myynti ja asiakkuudet
        </p>
        <p className="info">Y-tunnus 1846135-8</p>
        <p className="info">Yrityksen kotipaikka: Naantali </p>
        <p className="info">Yhteydenotot: Merja Saari 0400630070</p>
        <p className="info">merja.saari@digis.fi</p>
        <p className="info">www.digis.fi</p>
        <p className="info">
          <a className="text-link" href={tietosuojaseloste}>
            TIETOSUOJASELOSTE
          </a>
        </p>
      </section>
    </PageStyles>
  );
}
